var Towa = jQuery.extend({}, Towa || {});

;(function ($, $sc) {
    'use strict';

    Towa.Config = {
        $window: $(window),
        $html: $('html'),
        modulePaths: (typeof(vars) === 'undefined') ? '../../pub/js/modules/' : vars.themePath,
        prevent: false
    };

    //Base Functions
    Towa.Common = {
        init: function () {
            console.log();
            this.breakpoints();
            this.touchEvents();
            this.resize();
            this.newsletter();
        },
        touchEvents: function () {
            Towa.Config.isTouch = (Modernizr.touchevents || Modernizr.pointerevents);
        },
        newsletter: function() {
            var form = $('#kajomi'),
                button = $('#overlay-newsletter .form__btn--submit');

            // mixed content, kajomi only work with http -> write an email and fix it.
            button.on('click', function(e) {
                e.preventDefault();
                form.prop('action', 'http://vorarlberg.kjm4.de/signup.php').submit();
                form.prop('action', 'https://vorarlberg.kjm4.de/signup.php');
                return false;
            });
        },
        breakpoints: function () {
            Towa.Config.isMobile = (Modernizr.mq('only screen and (max-width: 768px)'));
            Towa.Config.isTablet = (Modernizr.mq('only screen and (max-width: 992px)'));
            Towa.Config.isDesktop = (Modernizr.mq('only screen and (min-width: 1200px)'));
        },
        resize: function () {
            Towa.Config.windowWidth = window.innerWidth;
            Towa.Config.windowHeight = window.innerHeight;

            $.debounce(250, Towa.Config.$window.on('resize', function () {
                if (window.innerHeight !== Towa.Config.windowHeight) {
                    Towa.Modules.calcHeight.init();
                }

                if (window.innerWidth !== Towa.Config.windowWidth) {
                    Towa.Config.windowWidth = window.innerWidth;
                    Towa.Common.breakpoints();

                    if (Towa.Config.isTablet && !Towa.Config.prevent) {
                        $('.footer .accordeon__header').on('click', Towa.Common.preventDefault);
                        Towa.Modules.adjustHeight.resetHeight();

                        Towa.Config.prevent = true;
                        console.log('tablet', Towa.Config.prevent, Towa.Config.isTablet);
                    }

                    if (!Towa.Config.isTablet && Towa.Config.prevent) {
                        $('.footer .accordeon__header').off('click', Towa.Common.preventDefault);
                        Towa.Modules.adjustHeight.init($('.adjust-height'));

                        Towa.Config.prevent = false;
                        console.log('desktop', Towa.Config.prevent, Towa.Config.isTablet);
                    }

                    if (Towa.Config.isDesktop && $('.section--story').length > 0) {
                        Towa.Modules.scrollReveal.calcHeight();
                    } else if (!Towa.Config.isDesktop && $('.section--story').length > 0) {
                        $('.section--story__absolute').css('min-height', 0)
                            .find('.section--story__item-padding').css('min-height', 0);
                    }

                    //if (Towa.Config.isTablet && Towa.Config.stopResize992) {
                    //    Towa.Base.adjustHeight($('.adjust-height'));
                    //    Towa.Config.stopResize992 = false;
                    //}
                }


            }));
        },
        preventDefault: function (e) {
            e.preventDefault();
        }
    };

    //Modules
    Towa.Modules = {
        //add module name that is also classname
        allModules: ['slider', 'video-player', 'accordeon', 'tab', 'dropdown', 'grid', 'nl-form', 'popup', 'brochures', 'map', 'tooltip', 'selectbox', 'ajax', 'outdooractive', 'outdoorprint', 'scrollreveal', 'audio-player', 'tour-overview', 'wishlist', 'gform_wrapper', 'kajomi', 'panorama', 'single-region', 'badge'],
        init: function () {
            // console.log('Modules');
            this.loadModules();
            //Towa.Modules.adjustHeight.init($('.adjust-height'));
        },
        //load all Modules in modules folder asynchronous
        loadModules: function () {
            for (var i = 0; i < Towa.Modules.allModules.length; i++) {
                var moduleName = Towa.Modules.allModules[i],
                    $module = $('.' + moduleName);

                if ($module.length > 0) {
                    Towa.Config.script(moduleName + '/' + moduleName);
                }
            }
        },
        unveil: (function () {
            var _ = {},
                self = {};

            self.init = function () {
                $('.unveil').unveil(500, function () {
                    $(this).addClass('unveil--loaded').closest('.grid-tile').addClass('unveil--loaded');
                });
            };

            self.init();

            return self;
        })(),
        navigation: (function ($obj) {
            var _ = {},
                self = {};

            if (!$obj.length) {
                return;
            }

            self.$obj = $obj;
            self.$objClass = $('[data-ontoggle-class]');

            _.onToggle = function (e) {
                var $that = $(this),
                    className = $that.data('ontoggle'),
                    forceOverlay = $that.data('force-overlay'),
                    hasOverlayClass = className.indexOf('on-overlay '),
                    $overflow = $('html, body');

                e.preventDefault();

                if (Towa.Config.$html.hasClass('on-overlay') && hasOverlayClass !== -1 && forceOverlay !== true) {
                    // console.log('remove');
                    Towa.Config.$html.removeClass(className);

                    // for multiple overlay weahter mobile close nav too
                    if (Towa.Config.$html.hasClass('on-overlay-nav-mobile')) {
                        Towa.Config.$html.removeClass('on-overlay-nav-mobile');
                    }
                    $overflow.removeClass('overflow-hidden');
                } else if (hasOverlayClass === -1 && Towa.Config.$html.hasClass('on-overlay')) {
                    // console.log('newClass');
                    Towa.Config.$html.toggleClass(className);
                } else {
                    // console.log('add');
                    Towa.Config.$html.addClass(className);
                    window.setTimeout(function () {
                        $overflow.addClass('overflow-hidden');
                    }, 300);
                }
            };

            //TODO: Close levels
            _.closeMobileNavi = function () {
                $('.nav__mobile').find('[type=checkbox]').removeAttr('checked');
            };

            _.toggleDesktopNavi = function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();

                var $that = $(this).parent();

                if ($that.hasClass('open')) {
                    $that.removeClass('open');
                } else {
                    $('.nav__has-content').removeClass('open');
                    $that.toggleClass('open');
                }

            };

            self.init = function () {
                //console.log('ModuleOne');
                self.$obj.on('click', _.onToggle);

                $('.nav--desktop .nav__has-content .nav__link').on('click touchend', _.toggleDesktopNavi);

                //$('.nav--desktop .nav__has-content').on('mouseenter', function(){
                //    var $that = $(this);
                //    $('.nav__has-content').removeClass('open');
                //
                //    setTimeout(function(){
                //        $('.nav__has-content').removeClass('open');
                //
                //        $that.addClass('open');
                //    },700);
                //
                //}).on('mouseleave', function(){
                //    $('.nav__has-content').removeClass('open');
                //});
            };

            self.init();

            return self;
        })($('[data-ontoggle]')),
        onChangeSize: (function ($obj) {
            var _ = {},
                self = {};

            if (!$obj.length) {
                return;
            }

            _.onChangeSize = function (e) {
                e.preventDefault();
                var $that = $(this),
                    fontSize = $that.attr('class').split('item-')[1];

                Towa.Config.$html.removeClass(function (index, css) {
                    return (css.match(/(^|\s)fz-\S+/g) || []).join(' ');
                }).addClass('fz-' + fontSize);

                if (Modernizr.cookies) {
                    document.cookie = 'fz=' + fontSize + ';';
                }
            };

            self.init = function () {
                if (Modernizr.cookies && document.cookie.indexOf('fz') !== -1) {
                    var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)fz\s*\=\s*([^;]*).*$)|^.*$/, "$1");
                    Towa.Config.$html.addClass('fz-' + cookieValue);
                }

                $obj.on('click', _.onChangeSize);
            };

            self.init();

            return self;
        })($('.header__fz-item')),
        calcHeight: (function () {
            var _ = {},
                self = {};

            self.$obj = $('.gl-calc-height');

            self.init = function () {
                if (!self.$obj.length) return;

                var height = window.innerHeight,
                    newHeight = height - $('.header').outerHeight();

                self.$obj.css('min-height', newHeight);
            };

            self.init();

            return self;
        })(),
        onHoverRegion: (function ($obj) {
            var _ = {},
                self = {};


            if (!$obj.length) {
                return;
            }

            _.onEnter = function () {
                var $that = $(this),
                    className = $that.data('onhover'),
                    $activeEl = $('.' + className).parent();

                _.$regions.removeClass('region__active');
                $activeEl.addClass('region__active');
            };

            _.onLeave = function () {
                _.$regions.removeClass('region__active');
            };

            self.init = function () {

                _.$obj = $obj;
                _.$regions = $('.region__group');

                _.$obj.on('mouseenter', _.onEnter);
                _.$obj.on('mouseleave', _.onLeave);
                //console.log('ModuleOne');
            };

            self.init();

            return self;
        })($('[data-onhover]')),
        adjustHeight: (function () {
            var _ = {},
                self = {};

            _.height = [];
            _.$obj = $('.adjust-height');

            _.calcOffset = function () {
                _.$objWrapper.each(function () {
                    var $container = $(this);
                    _.height = [];

                    $container.each(function () {
                        var $item = $(this).find('.adjust-height__item'),
                            $itemOffset = $(this).find('.adjust-height__offset'),
                            max, offset;

                        $item.css('height', 'auto');

                        if ($itemOffset.length) {
                            offset = parseInt($itemOffset.css('margin-top'), 10);
                        }

                        $item.each(function (i) {
                            var $that = $(this),
                                height = $that.outerHeight();

                            _.height[i] = ($that.hasClass('adjust-height__offset')) ? height + offset : height;
                        });

                        max = Math.max.apply(null, _.height);

                        $item.css('height', max);
                        $itemOffset.css('height', max - offset);
                    });
                });
            };

            self.resetHeight = function () {
                if (!_.$obj.length) {
                    return;
                }

                _.$objWrapper.find('.adjust-height__item').css('height', 'auto');
            };

            self.init = function (obj) {
                if (!obj.length) {
                    return;
                }

                _.$objWrapper = obj;

                //explicit false, should work when its undefined
                if(Towa.Config.isTablet === false){
                    //console.log(Towa.Config.isTablet, 'ModuleOne');
                    _.calcOffset();
                }
            };

            self.init($('.adjust-height'));

            return self;
        })()

        // moduleOne: (function () {
        //    var _ = {},
        //        self = {};
        //
        //    _.priv = "Privat";
        //
        //    self.init = function(){
        //        //console.log('ModuleOne');
        //    };
        //
        //    self.init();
        //
        //    return self;
        //})()
    };

    // console.log(Towa);

    Towa.Config.script = $sc;
    Towa.Config.script.path(Towa.Config.modulePaths);

    Towa.Common.init();
    Towa.Modules.init();


})(jQuery, $script);
